import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';
import { getSlugLocalesWithArchives } from '../utils';

const BlogPostTemplate = ({
  data: { datoCmsBlog, allDatoCmsBlogArchive },
  pageContext: { locale },
}) => {
  const { seoMetaTags, slugLocales, title, banner, modularBlocks } =
    datoCmsBlog;

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={getSlugLocalesWithArchives(
        slugLocales,
        allDatoCmsBlogArchive
      )}
      page={datoCmsBlog}
    >
      <main>
        <Banner
          heading={title}
          text={banner.text}
          link={banner.link}
          image={banner.image}
        />
        <ModularBlocks items={modularBlocks} reduceGaps={true} />
      </main>
    </Layout>
  );
};

export const BlogPostTemplateQuery = graphql`
  query BlogPostTemplateQuery($id: String!) {
    datoCmsBlog(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slugLocales: _allSlugLocales {
        locale
        value
      }
      title
      banner {
        ...BannerFragment
      }
      modularBlocks {
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...InternalVideoModularBlockFragment
      }
    }
    allDatoCmsBlogArchive {
      nodes {
        locale
        slug
      }
    }
  }
`;

export default BlogPostTemplate;
